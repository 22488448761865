import React from "react";
import "./Now.css";

function Now() {
  return (
    <div className="now">
      <div className="updated">
        <p id="now-p">Updated: September, 2024</p>
      </div>
      <h4 className="now-title">What I am doing now:</h4>
      <div className="now-section">
        <h4>Work:</h4>
        <p id="now-p">
          After finishing a Python course and a Python project, I am back to
          studying some function and system design concepts between work tasks.
          Lately work has been diving into Laravel deeper, and planning the DB
          schema for a large upcoming suite application.
        </p>
      </div>
      <div className="now-section">
        <h4>Family:</h4>
        <p id="now-p">The twins are obsessed with pumpkins and dinosaurs.</p>
      </div>
      <div className="now-section">
        <h4>Me:</h4>
        <p id="now-p">
          My new obsession is leatherworking. Updates on my belt to come.
        </p>
      </div>
    </div>
  );
}

export default Now;
