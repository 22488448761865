import React from "react";
import Now from "../components/Now";

function NowScreen() {
  return (
    <div>
      <Now />
    </div>
  );
}

export default NowScreen;
