import React from "react";
import ContactInfo from "../components/ContactInfo";

function ContactScreen() {
  return (
    <div>
      <ContactInfo />
    </div>
  );
}

export default ContactScreen;
