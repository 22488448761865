import React from "react";
import "./Projects.css";
import fitsnap from "../assets/fitsnap.png";
import omhf from "../assets/omhf.png";
import jrjob from "../assets/jrjob.png";
import { BsYoutube } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BiWorld } from "react-icons/bi";
import churchLogo from "../assets/church-logo2.jpeg";
import iworq from "../assets/iworq.png";

function Projects() {
  return (
    <div className="project-wrapper">
      <div className="left-side">
        <div className="project">
          <h3 className="church-title">iWorQ Systems</h3>
          <div>
            <img src={iworq} alt="iworq logo" />
          </div>
          <div className="icons">
            <a
              href="https://www.iworq.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BiWorld className="grow" />
            </a>
          </div>
          <p className="p-title">
            Full-stack software engineer specializing in new suite applicaitons,
            and planning and developing start to end.
          </p>
          <p className="p-para">
            On the new development team, I get to be a part of every stage of
            developing new suite applications like DB schema planning, scoping,
            building, and bug fixing. I especially like to see my direct
            influence on new products going out for sale. I also have taken the
            initiative to build a new application on top of my workload that
            allows me to spearhead and take ownership of the product and its
            eventual release.
          </p>
        </div>
        <div className="project omhf">
          <h3>FitSnap</h3>
          <div>
            <img src={fitsnap} alt="fitsnap" />
          </div>
          <div className="icons">
            <a
              href="https://www.youtube.com/watch?v=OZAOSipTCvA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsYoutube className="grow" />
            </a>
            <a
              href="https://github.com/pmosscs/fitsnap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsGithub className="grow" />
            </a>
          </div>
          <p className="p-title">
            A social media app that allows you to post your fitness milestones
            and graph your monthly averages.
          </p>
          <p className="p-para">
            This project was built in two weeks as a ground-up full-stack
            project to challenge my skills in React, Node.js, Express,
            PostgreSQL, and more. My favorite discoveries were my love for
            working with databases, and overcoming puzzling challenges in the
            user sign-in authentication feature.
          </p>
        </div>
        <div className="project">
          <h3>JrJobHunt.com</h3>
          <div>
            <img src={jrjob} alt="fitsnap" />
          </div>
          <div className="icons">
            <a href="/" target="_blank" rel="noopener noreferrer">
              {/* change these and make them unclickable */}
              <BiWorld className="grow" />
            </a>
            <a
              href="https://github.com/pmosscs/jrjobhunt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsGithub className="grow" />
            </a>
          </div>
          <p className="p-title">
            An email marketing landing page for capturing emails.
          </p>
          <p className="p-para">
            This project was built to use the Send In Blue API to capture user
            emails and manage double-opt-in transactional emails for varifying
            users. Built with React on the front end, including form
            verification, and Nodejs on the server side to manage the SIB API
            calls.
          </p>
        </div>
      </div>
      <div className="middle-bar"></div>
      <div className="right-side">
        <div className="project ohfm2">
          <h3 className="church-title">
            Church of Jesus Christ of Latter-Day Saints
          </h3>
          <div>
            <img src={churchLogo} alt="church logo" />
          </div>
          <div className="icons">
            <a
              href="https://www.churchofjesuschrist.org/?lang=eng"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BiWorld className="grow" />
            </a>
          </div>
          <p className="p-title">
            Full-stack software engineer touching every step of the SDLC,
            working on one of the many apps of the Temple Portfolio.
          </p>
          <p className="p-para">
            Working with an amazing agile team, we fixed bugs and enhanced a
            large Java/Angular.js application that uses and organizes data from
            multiple databases, to be used processing and managing hundreds of
            thousands of patrons in hundreds of locations. It was an incredible
            experience being thrown in the fire and experienceing the learning
            curve of working with a giant application and making changes
            everyday. Other skills used: SQL, GraphQL, Spring, and writing unit
            tests with JUnit.
          </p>
        </div>
        <div className="project">
          <h3>Off Market House Finder</h3>
          <div>
            <img src={omhf} alt="omhf" />
          </div>
          <div className="icons">
            <a
              href="https://www.youtube.com/watch?v=4fJQs2EFKTU&t=2s"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsYoutube className="grow" />
            </a>
            <a
              href="https://github.com/pmosscs/foundations_capstone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsGithub className="grow" />
            </a>
          </div>
          <p className="p-title">
            A database that allows you to manage lists of real estate properties
            for marketing campaigns.
          </p>
          <p className="p-para">
            My first full stack project using Javascript, Node.js, Express, and
            PostgreSQL. Solidifying my abily to make servers, call enpoints, and
            use that data on the front-end was a big moment in my learning. My
            other big take-away was CSS flexbox - my best friend.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Projects;
